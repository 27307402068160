<template>
  <section id="gallery-form">
    <SectionTabs :tabs="tab_component"/>

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          <font-awesome-icon 
            icon="image" 
            class="color-secondary pe-1"
          />
          Completa los siguientes campos para publicar un álbum.
        </h3>
      </div>
    </div>

    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">
        <div class="row flex-grow-1 mt-3">
          <div class="col-12 col-xl-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">Datos del álbum</h5>
            <div class="card flex-grow-1">
              <div class="card-body">

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Añade un título</label>
                    <input 
                      type="text" 
                      class="form-control input-custom" 
                      maxlength="100" 
                      v-model="album_title" 
                      ref="albumTitle"
                    />
                    <div class="form-text text-end">
                      {{ album_title.length }} /100
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Fecha de publicación</label><br/>
                    <small 
                      v-if="edit_date != 'question'"
                      class="text-secondary">
                      {{ edit_date }}
                    </small>
                    <small 
                      v-if="edit_date == 'question'" 
                      class="text-secondary">
                      Selecciona la fecha que deseas publicar el álbum
                    </small>
                    <div v-if="edit_date == 'question'">
                      <v-date-picker 
                        color="blue" 
                        style="width:100%;"
                        :key="key_calendar" 
                        :min-date="min_date" 
                        @dayclick="onDayClick" 
                        v-model="calendar_model" 
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12 col-xl-8 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              Selecciona las imágenes que se cargarán en el álbum
                <font-awesome-icon 
                  :icon="['fa-regular','circle-question']"
                  content="Aquí podrás seleccionar las imágenes en formato JPG/PNG, que se cargarán en el álbum."
                  v-tippy="{ arrow: true }"
                />
            </h5>
            <div class="upload-image">
              <label
                v-if="handleUploadImg()"
                class="upload-image-label"
                for="input-file">
                <span class="upload-image-label-icon">
                  <i class="fa-solid fa-arrow-up-from-bracket color-secondary"></i>
                </span>
                <input 
                  type="file" 
                  id="input-file" 
                  @change="loadImages($event.target.files)"
                  accept="image/jpeg, image/png, image/jpg" 
                  style="display: none" 
                  multiple 
                />
                <span class="upload-image-label-text">
                  Haz clic aquí<br> 
                  <small>para seleccionar las imágenes</small>
                </span>
              </label>
              <div 
                class="upload-image-preview row" 
                v-if="handleShowPreview()"
              >
                <div 
                  class="col-6 col-md-4 col-xl-4 col-xxl-3 mb-2" 
                  v-for="(img, i) in album_draft" 
                  :key="i"
                >
                <div class="upload-image-preview-album">
                  <img 
                    :src="img.url_imagen_original" 
                    class="upload-image-preview-album-img" 
                  />
                  <div class="upload-image-preview-album-remove">
                    <a 
                      href="javascript:"
                      class="upload-image-preview-album-remove-link"
                      content="Eliminar" 
                      v-tippy="{ arrow: true }" 
                      @click="deleteImg(img)"
                    >
                      <font-awesome-icon icon="trash-alt"/>
                  </a>
                </div>
                  </div>
                </div>
              </div>
            </div>

            <div 
              v-if="handleShowPreview()"
              class="row mt-3">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <label 
                  for="input-file-alt"
                  class="btn btn-custom-color-white border border-round-50 mw-100"
                >
                  <input 
                    type="file" 
                    id="input-file-alt" 
                    @change="loadImages($event.target.files)"
                    accept="image/jpeg, image/png, image/jpg" 
                    style="display: none" 
                    multiple 
                  />
                  <font-awesome-icon icon="plus" class="pe-1"/>
                  Agregar más imágenes
                </label>
                <span class="border px-2 py-1 border-round-10 text-secondary">{{ countImagesDraft() }} imágenes</span>
              </div>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
              <button 
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="openModalQuestion()"
              >
                Volver
              </button>
              <button
                v-if="!edit_album"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="activePublishBtn() && createAlbum()"
                :disabled="!activePublishBtn()"
              >
                Agregar
              </button>
              <button
                v-if="edit_album"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="handleEditBtn() && editAlbum()"
                :disabled="!handleEditBtn()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div> 
            
      </div>
    </div>

    <Status 
      v-if="open_modal_status" 
      :msg="modal_status_msg" 
      :status="modal_status" 
      @close="closeStatus" 
    />
    <Question 
      v-if="open_question_modal" 
      :hideCancel="question_modal_hide_cancel" 
      :msg="question_modal_msg"
      @accept="acceptQuestion" 
      @cancel="cancelQuestion" 
    />
    <Question 
      v-if="open_question_modal_compressor" 
      :hideCancel="false" 
      :msg="question_modal_compressor_msg"
      @accept="acceptQuestionCompressor" 
      @cancel="cancelQuestionCompressor" 
    />
    <Spinner v-if="show_spinner"/>
    <LoadingProgress 
      :value="loading_progress" 
      v-if="show_progress" 
    />
  </section>

</template>

<script>
  import Question from "../Modales/Question.vue";
  import Status from "../Modales/Status.vue";
  import Spinner from "../Spinner.vue";
  import { mapActions, mapState } from "vuex";
  import { GET_GALERIA } from "@/apollo/queries";
  import SectionTabs from "../Section/SectionTabs.vue";
  import * as imageConversion from 'vue-image-conversion';
  import LoadingProgress from "../LoadingProgress.vue";

export default {
  components: { Question, Status, Spinner, SectionTabs, LoadingProgress },
  data() {
    return {
      tab_component: [
        {
          name: "Galería",
          route: "gallery-list",
        },
        {
          name: "Agregar",
          route: "gallery-add",
        },
      ],
      step_title: "Agregar título",
      step: "title",
      album_title: "",
      album_id: "",
      album_date: null,
      album_draft: [],
      display_button: "publish",
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      edit_album: false,
      new_album: [],
      show_spinner: false,
      show_progress: false,
      loading_progress: 0,
      exit_add: false,
      open_question_modal: false,
      question_modal_msg: "",
      question_modal_hide_cancel: true,
      current_date: new Date(),
      min_date: "",
      calendar_model: new Date(),
      switch_btn: false,
      subtitle: "Completa los siguientes campos para publicar un álbum.",
      key_calendar: 0,
      delete_preview_photo: false,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
      album_obj_edit: [],
      edit_date: "question",
      going_back: false,
      open_question_modal_compressor: false,
      album_size_limit: 20/* MB */ * 1024 * 1024,
      /* tiempo promedio de lo que demora la compresión de 1 imagen: */
      meanTimeCompressionPerImage: 5 /* segundos */
    };
  },
  computed: {
    ...mapState("galleryModule", ["album_list"]),

    // Funcion para mostrar array de imagenes en hileras/row de 4 elementos
    rowCount() {
      return Math.ceil(
        this.album_draft
          .filter(img => img.url_imagen_original !== "deleteImg")
          .length / 4
      );
    },

    // Funcion del plugin del calendario para destacar el dia seleccionado
    attributes() {
      return {
        highlight: {
          color: "orange",
          fillMode: "light",
        },
      };
    },

    question_modal_compressor_msg() {
      return `El total sobrepasa los ${this.album_size_limit / 1024 / 1024} MB permitidos.
        Comprimiremos tus imágenes, por lo que podrían bajar su calidad.`;
    }

  },

  mounted() {
    this.album_date = this.current_date;
    if (this.$route.params.id != undefined && this.$route.params.id != null) {
      this.edit_album = true;
      this.getAlbum(this.$route.params.id);
    } else {
      this.album_date = this.getCurrentDate();
    }
  },

  methods: {
    ...mapActions("galleryModule", ["addGalleryAction", "editGalleryAction"]),

    countImagesDraft() {
      return this.album_draft
        .filter(img => img.url_imagen_original !== "deleteImg")
        .length;
    },

    sumLoadedImagesSize() {
      if (this.edit_album) {
        let totalSize = 0;
        for (const img of this.album_draft) {
          this.getImageSize(img.url_imagen_original)
            .then(size => totalSize += size)
            .catch(err => console.log("error:", err));
        }
        return totalSize;
      } else {
        return this.album_draft
          .filter(img => img.url_imagen_original !== "deleteImg")
          .map(img => +img.size)
          .reduce((acc, currVal) => acc + currVal, 0);
      }
    },

    sumFilesSize() {
      return this.files
        .map(file => +file.size)
        .reduce((acc, currVal) => acc + currVal, 0);
    },

    goBack() {
      let actual_route = this.$route.matched[1].path;
      if (actual_route == '/galeria/add') {
        this.$router.push('./list')
      } else {
        this.$router.push('../list')
      }
    },

    openModalQuestion() {
      this.open_question_modal = true;
      this.question_modal_hide_cancel = false;
      this.question_modal_msg = '¿Realmente deseas salir? Se perderán los cambios realizados.'
      this.going_back = true;
    },

    async getAlbum(id) {
      this.show_spinner = true;
      var album = null;
      if (this.album_list.length != 0) {
        album = this.album_list.find((el) => el.id_galeria == id);
        this.show_spinner = false;
      } else {
        let data_ = {
          id_empresa: this.id_empresa,
          id_usuario: this.id_usuario,
          id_galeria: id,
          plataforma: '2'
        }
        album = await this.getAlbumById(data_);
      }
      if (album) {
        this.album_obj_edit = album;
        this.album_title = album.titulo_galeria;
        this.album_id = album.id_galeria;
        this.album_date = album.fecha_galeria;
        var date_split = album.fecha_galeria.split("-");
        var dateToDay = new Date();
        var date = new Date(
          parseInt(date_split[0]),
          parseInt(date_split[1]) - 1,
          parseInt(date_split[2])
        );

        this.calendar_model = new Date(date);
        this.key_calendar = this.key_calendar + 1;
        if (album.imagenes.length > 0) {
          for (let i = 0; i < album.imagenes.length; i++) {
            this.album_draft.push(album.imagenes[i]);
          }
          if (dateToDay > date) {
            this.edit_date = `${parseInt(date_split[2]) < 10
              ? `0${parseInt(date_split[2])}`
              : parseInt(date_split[2])
              }/${parseInt(date_split[1]) - 1 < 10
                ? `0${parseInt(date_split[1])}`
                : parseInt(date_split[1])
              }/${parseInt(date_split[0])}`;
          }
        }
        this.show_spinner = false;
      }
    },

    // Funcion para mostrar array de imagenes en hileras/row de 4 elementos
    itemCountInRow(index) {
      // se recorre el array para no considerar las img que van a ser eliminadas
      return this.album_draft
        .filter(img => img.url_imagen_original !== "deleteImg")
        .slice((index - 1) * 4, index * 4);
    },

    readImage(file) {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader.result;
        const new_img = {
          id_imagen: "ID-" + Date.now().toString(),
          url_imagen_original: `${dataURL}`,
          size: file.size
        };
        this.album_draft.push(new_img);
      };

      reader.onloadend = () => {
        this.show_spinner = false;
      };

      reader.readAsDataURL(file);
    },

    loadImages(fileList) {
      const files = Array.from(fileList);
      const someNonImgFile = files.some(file => (file.type !== "image/jpeg") && (file.type !== "image/png"));

      if (someNonImgFile) {
        this.show_progress = false;
        this.$toast.open({
          message: 'Uno de los archivos no es una imagen. Por favor asegúrate que todos los archivos sean .jpeg o .png y vuelve a intentar.',
          type: 'error',
          duration: 0,
          position: 'top-right'
        });
        return;
      }

      this.files = files;
      const total_imgs_size = this.sumLoadedImagesSize() + this.sumFilesSize();

      if (total_imgs_size > this.album_size_limit) {
        console.log(`total_imgs_size (${total_imgs_size / 1024 / 1024} MB) > this.album_size_limit (${this.album_size_limit / 1024 / 1024} MB)`);
        this.open_question_modal_compressor = true;
      } else {
        for (let i = 0; i < files.length; i++) {
          this.readImage(files[i]);
        }
      }

    },

    deleteImg(selected_img) {
      const item = this.album_draft.findIndex((el) => {
        return el.id_imagen == selected_img.id_imagen;
      });
      // new_img se usa para diferenciar si son imagenes que venian en el album
      // o son imgs añadidas al momento de editar
      const new_img = selected_img.id_imagen.includes("ID-");

      // Si se esta editando el album, la imagen a eliminar (que ya viene en el album)
      // se vuelve a agregar al array para que el ws sepa cual debe sacar
      if (this.edit_album && !new_img) {
        const img = {
          id_imagen: selected_img.id_imagen,
          url_imagen_original: "deleteImg",
          url_imagen_thumb: "deleteImg",
        };
        this.album_draft.splice(item, 1, img);
      } else {
        this.album_draft.splice(item, 1);
      }
    },

    // Funcion para cerrar modal que muestra el estatus de la accion (exito o error)
    closeStatus() {
      this.open_modal_status = false;

      if (this.exit_add) {
        this.$router.push({ name: "gallery-list" });
      }
    },

    // Transforma array de objetos en objeto con arrays
    getPayloadData() {
      const result = {
        imagenesOriginales: [],
        formatosOriginales: [],
        imagenesThumb: [],
        formatosThumb: []
      };

      if (this.edit_album) {
        result.idImagenes = [];
      }

      for (const { original, format_original, thumb, format_thumb, id_imagen } of this.new_album) {
        result.imagenesOriginales.push(original);
        result.formatosOriginales.push(format_original);
        result.imagenesThumb.push(thumb);
        result.formatosThumb.push(format_thumb);
        if (this.edit_album) {
          result.idImagenes.push(id_imagen);
        }
      }

      return result;
    },

    setNewAlbum() {
      for (const { url_imagen_original, id_imagen } of this.album_draft) {
        let imageData = {
          id_imagen: "0",
        };

        if (url_imagen_original.includes("data:image/")) {
          const img = new Image();
          img.src = url_imagen_original;
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = 320;
          canvas.height = 240;
          // Draw source image into off-screen canvas
          ctx.drawImage(img, 0, 0, 320, 240);
          const miniImg = canvas.toDataURL();
          imageData = {
            original: url_imagen_original.split(",")[1],
            format_original: url_imagen_original.split(":")[1].split(";")[0],
            thumb: miniImg.split(",")[1],
            format_thumb: miniImg.split(":")[1].split(";")[0]
          };
        } else if (url_imagen_original.includes("https")) {
          imageData = {
            original: "",
            format_original: "",
            thumb: "",
            format_thumb: "",
            id_imagen: id_imagen
          };
        } else if (url_imagen_original == "deleteImg") {
          imageData = {
            original: "deleteImg",
            format_original: "deleteImg",
            thumb: "deleteImg",
            format_thumb: "deleteImg",
            id_imagen: id_imagen
          };
        }

        this.new_album.push(imageData);
      }
    },

    async editAlbum() {
      this.show_progress = true;
      this.loading_progress = 0;
      const progress_step = 100 / this.album_draft.length;
      this.setNewAlbum();
      var data = this.getPayloadData();
      var date = this.handleAlbumDate(this.album_date)
        .split("-")
        .reverse()
        .join("-");

      if (!this.album_date) {
        this.show_spinner = false;
        this.open_modal_status = true;
        this.modal_status_msg = "Se debe seleccionar una fecha";
        this.modal_status = false;
        return;
      }

      // Verifica que el álbum tenga al menos 1 imagen
      const everyImageDeleted = this.album_draft.every(
        (img) => img.url_imagen_original == "deleteImg"
      );

      if (everyImageDeleted) {
        this.show_spinner = false;
        this.open_modal_status = true;
        this.modal_status_msg = "Se debe seleccionar al menos una imagen";
        this.modal_status = false;
        return;
      }

      if (this.album_title.length === 0) {
        this.show_spinner = false;
        this.open_modal_status = true;
        this.modal_status_msg = "El álbum debe tener un título";
        this.modal_status = false;
        return;
      }

      for (let i = 0; i < data.formatosOriginales.length; i++) {
        const payload = {
          titulo_galeria: this.album_title,
          id_imagenes: data.idImagenes[i] ? [data.idImagenes[i]] : ["0"],
          imagenes_originales: [data.imagenesOriginales[i]],
          formatos_originales: [data.formatosOriginales[i]],
          imagenes_thumb: [data.imagenesThumb[i]],
          formatos_thumb: [data.formatosThumb[i]],
          id_empresa: this.id_empresa,
          id_galeria: this.album_id,
          fecha: date,
          id_usuario: this.id_usuario,
          tipo_segementacion: "",
          segmentaciones: "",
          alerta_push: "0",
        };

        const res = await this.editGalleryAction(payload);
        this.loading_progress += progress_step;

        if (!res) {
          this.show_progress = false;
          this.loading_progress = 0;
          this.toastEditGalleryError();
          return;
        }
      }

      this.show_progress = false;
      this.toastEditGallerySuccess();
      this.new_album = [];
      this.album_draft = [];
      this.album_title = "";
      this.exit_add = true;
      this.goBack();
    },

    getCurrentDate() {
      var day = this.current_date.getDate();
      var month = this.current_date.getMonth() + 1;
      var year = this.current_date.getFullYear();

      if (day < 10) {
        day = "0" + day.toString();
      }
      if (month < 10) {
        month = "0" + month.toString();
      }

      return year + "-" + month + "-" + day;
    },

    async createAlbum() {
      this.show_progress = true;
      this.loading_progress = 0;
      const progress_step = 100 / this.album_draft.length;
      this.setNewAlbum();
      const data = this.getPayloadData();
      const date = this.handleAlbumDate(this.album_date)
        .split("-")
        .reverse()
        .join("-");

      for (let i = 0; i < data.formatosOriginales.length; i++) {
        const payload = {
          titulo_galeria: this.album_title,
          id_empresa: this.id_empresa,
          fecha: date,
          id_usuario: this.id_usuario,
          tipo_segementacion: "",
          segmentaciones: "",
          alerta_push: "0",
          imagenes_originales: [data.imagenesOriginales[i]],
          formatos_originales: [data.formatosOriginales[i]],
          imagenes_thumb: [data.imagenesThumb[i]],
          formatos_thumb: [data.formatosThumb[i]],
          id_imagenes: ["0"]
        };

        let res = null;

        if (i === 0) {
          // Creación álbum
          res = await this.addGalleryAction(payload);
          this.album_id = res.id_galeria;
          if (!res) {
            this.show_progress = false;
            this.loading_progress = 0;
            this.toastCreateGalleryError();
            return;
          }
        } else {
          // Adición de imágenes al álbum
          Object.assign(payload, { id_galeria: this.album_id });
          res = await this.editGalleryAction(payload);
          if (!res) {
            this.show_progress = false;
            this.loading_progress = 0;
            this.toastCreateGalleryOnProgressError();
            return;
          }
        }
        this.loading_progress += progress_step;
      }

      this.show_progress = false;
      this.toastCreateGallerySuccess();
      this.new_album = [];
      this.album_draft = [];
      this.album_title = "";
      this.exit_add = true;
      this.goBack();
    },

    async compressImages() {
      const progress_step_per_image = 100 / (this.files.length);
      this.show_progress = true;
      const progressPerSecond = this.meanTimeCompressionPerImage * (this.files.length) / 100;

      const progressInterval = setInterval(() => this.loading_progress += 1, progressPerSecond * 1000);

      const compressedImgSize = (this.album_size_limit / 1024) / (this.countImagesDraft() + this.files.length) - 2048;
      const files_temp = [];
      for (const idx in this.files) {
        files_temp.push((await imageConversion.compressAccurately(this.files[idx], compressedImgSize)));
        this.loading_progress = progress_step_per_image * idx;
      }
      this.show_progress = false;
      clearInterval(progressInterval);
      this.loading_progress = 0;

      const files_temp_size = files_temp.map(f => +f.size).reduce((acc, currVal) => acc + currVal, 0);
      const total_imgs_size = this.sumLoadedImagesSize() + files_temp_size;
      if (total_imgs_size > this.album_size_limit) {
        this.$toast.open({
          message: 'El peso total de las imágenes aún excede el máximo permitido.\
            Por favor intenta con menos imágenes o con imágenes menos pesadas.',
          type: 'error',
          duration: 6000,
          position: 'top-right'
        });
      } else {
        this.files = files_temp;
        this.loadImages(this.files);
      }
    },

    acceptQuestionCompressor() {
      this.open_question_modal_compressor = false;
      this.question_modal_hide_cancel = true;
      this.compressImages();
    },
    cancelQuestionCompressor() {
      this.open_question_modal_compressor = false;
      this.question_modal_hide_cancel = true;
      this.files = null;
    },

    acceptQuestion() {
      this.open_question_modal = false;
      this.question_modal_hide_cancel = true;
      // this.cancelEdit();
      if (this.going_back) {
        this.goBack();
      }
    },

    cancelQuestion() {
      this.open_question_modal = false;
      this.question_modal_hide_cancel = true;
      this.going_back = false;
    },

    // Funcion para seleccionar fecha
    onDayClick(day) {
      this.album_date = day.id;
      // this.calendar_model = new Date(day.id);
    },
    // Funcion para mostrar la fecha del album formateada
    handleAlbumDate(date) {
      var x = date.split(/\D/g);
      return [x[2], x[1], x[0]].join("-");
    },
    handleToggleSwitch() {
      if (this.switch_btn) {
        this.switch_btn = false;
      } else {
        this.switch_btn = true;
      }
    },
    cleanInputs() {
      this.album_title = "";
      this.album_draft = [];
      this.album_date = new Date();
    },
    activePublishBtn() {
      if (
        this.album_title.length > 0 &&
        this.album_draft.length > 0 &&
        this.album_date !== null
      ) {
        return true;
      } else return false;
    },
    cancelEdit() {
      this.album_title = "";
      this.album_id = "";
      this.album_date = null;
      this.album_draft = [];
    },
    // funcion para detectar cambios y activar el boton editar
    handleEditBtn() {
      // detectando cambios en las fotos
      if (this.album_obj_edit.imagenes) {
        if (this.album_draft.length !== this.album_obj_edit.imagenes.length) {
          return true;
        } else {
          for (let i = 0; i < this.album_draft.length; i++) {
            if (this.album_draft[i].url_imagen_original == "deleteImg") {
              return true;
            }
          }
        }

        if (this.album_title !== this.album_obj_edit.titulo_galeria) {
          return true;
        } else if (this.album_date !== this.album_obj_edit.fecha_galeria) {
          return true;
        } else {
          return false;
        }
      }

    },

    // funcion para mostrar o no el pinput que sube imagenes
    handleUploadImg() {
      if (this.edit_album) {
        var arr_every = this.album_draft.every(
          (img) => img.url_imagen_original == "deleteImg"
        );
        // si todas las fotos del album_draft son para eliminar debe mostrar
        // el input principal para poder subir imgs
        if (arr_every) {
          return true;
        }
      } else {
        if (this.album_draft.length == 0) {
          return true;
        }
      }
    },

    handleShowPreview() {
      if (this.edit_album) {
        if (this.album_draft.length > 0) {
          var arr_every = this.album_draft.every(
            (img) => img.url_imagen_original == "deleteImg"
          );
          if (!arr_every) {
            return true;
          }
        }
      } else {
        if (this.album_draft.length > 0) {
          return true;
        }
      }
    },

    async getImageSize(url) {
      let size = 0;
      try {
        const response = await fetch(url, { method: 'HEAD' });
        size = response.headers.get("content-length");
      } catch (error) {
        console.error("Error getImageSize:", error);
      }
      return size;
    },

    async getAlbumById(data) {
      let response = {};
      try {
        const res = await this.$apollo.query({
          query: GET_GALERIA,
          variables: data,
        });
        response = res.data.galeria;
      } catch (error) {
        this.show_spinner = false;
        this.open_modal_status = true;
        this.modal_status_msg = "Error al intentar obtener datos del album seleccionado, intenta nuevamente.";
        this.modal_status = false;
      }

      return response;
    },

    toastCreateGallerySuccess() {
      this.$toast.open({
        message: 'Álbum creado correctamente.',
        type: 'success',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastCreateGalleryError() {
      this.$toast.open({
        message: 'Ocurrió un error al crear el álbum. Por favor inténtalo nuevamente.',
        type: 'error',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastCreateGalleryOnProgressError() {
      this.$toast.open({
        message: `Álbum creado correctamente, sin embargo hay imágenes que no se subieron correctamente.\n
          Por favor revisa e inténtalo nuevamente.`,
        type: 'warning',
        duration: 10000,
        position: 'top-right'
      });
    },
    toastEditGallerySuccess() {
      this.$toast.open({
        message: 'Álbum editado correctamente.',
        type: 'success',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastEditGalleryError() {
      this.$toast.open({
        message: 'Ocurrió un error al editar el álbum. Por favor inténtalo nuevamente.',
        type: 'success',
        duration: 5000,
        position: 'top-right'
      });
    }

  }
};
</script>
